"use client";

import { Book, MessageCircle, Feather, Users, DollarSign } from "lucide-react";
import { motion } from "framer-motion";
import Testimonials from "./Testimonials";
import Acesso from "./Acesso";
import TeacherJu from "./images/ju.png";

export default function TeacherJuPortfolio() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="min-h-screen font-mono relative overflow-hidden">
      <div className="absolute inset-0 bg-beige-100">
        <div className="absolute"></div>
        <div className="absolute"></div>
      </div>
      <div className="relative min-h-screen border-indigo-100">
        <motion.header
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-sorbet-purple p-8 text-center text-gray-700 border-b border-gray-700"
        >
          <h1 className="text-5xl font-bold mb-2 font-mono text-gray-700">
            Teacher Ju
          </h1>
        </motion.header>

        <motion.main
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="container mx-auto px-4 py-12"
        >
          <motion.section
            variants={itemVariants}
            className="mb-16 bg-sorbet-purple/60 p-8 rounded-lg border border-gray-700 backdrop-blur-sm"
          >
            <h2 className="text-4xl font-bold mb-6 text-gray-700 flex items-center">
              <Feather className="w-8 h-8 mr-3" />
              About Me
            </h2>
            <div className="flex gap-8 flex-wrap justify-center">
              <p className="text-2xl text-gray-700">
                Olá! Sou a teacher Ju! Sou apaixonada no inglês desde muito
                novinha. Sou professora de inglês com experiência há mais de 4
                anos. Minha abordagem vai de encontro com o método comunicativo
                de aprendizagem, onde, através da fala, exploramos todos os
                aspectos da língua.
              </p>
              <img
                className="h-auto w-full max-w-96 rounded-[24px] border-1 border-gray-800"
                src={TeacherJu}
              />
            </div>
          </motion.section>

          <motion.section variants={itemVariants} className="mb-16">
            <h2 className="text-4xl font-bold mb-6 text-gray-700 flex items-center">
              <Book className="w-8 h-8 mr-3" />
              Sobre as aulas
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                {
                  icon: Users,
                  title: "Funcionamento das aulas",
                  description: (
                    <div className="items-center flex content-center justify-center">
                      <ul className="list-disc text-xl p-4">
                        <li className="pt-2">
                          Elas acontecem via Google Meet, com link enviado via
                          Whatsapp.
                        </li>
                        <li className="pt-2">
                          As aulas normalmente são divididas em 2 dias da
                          semana, com duração de 1 hora cada.
                        </li>
                        <li className="pt-2">
                          Os dias e horários são combinados diretamente via
                          Whatsapp
                        </li>
                      </ul>
                    </div>
                  ),
                },
                {
                  icon: DollarSign,
                  title: "Valores",
                  description: (
                    <div className="text-center">
                      <h2 className="text-3xl mb-4">
                        Para 2 horas de aula por semana
                      </h2>
                      <h3 className="text-5xl">R$ 210,00</h3>
                      <h3 className="text-xl mt-4">Por mês</h3>
                      <p className="text-sm mt-4 text-justify">
                        *Os pagamentos são feitos no 5º dia útil do mês <br />
                        *Não fechamos com menos de 2hrs semanais. Esse é o
                        mínimo. Se quiser mais horas, podemos fechar pacotes
                        diferentes via Whatsapp
                      </p>
                    </div>
                  ),
                },
              ].map((service, index) => (
                <motion.div
                  key={index}
                  className="bg-sorbet-purple/60 p-8 rounded-lg border border-gray-700 backdrop-blur-sm"
                  whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                >
                  <div className="flex items-center mb-4">
                    <div>
                      <service.icon className="w-10 h-10 mr-4 text-beige-300" />
                    </div>
                    <h3 className="text-2xl font-bold text-gray-700">
                      {service.title}
                    </h3>
                  </div>
                  {service.description}
                </motion.div>
              ))}
            </div>
          </motion.section>

          <div style={{ marginBottom: "50px" }}>
            <Testimonials />
          </div>

          <div style={{ marginBottom: "50px" }}>
            <Acesso />
          </div>

          <motion.section variants={itemVariants}>
            <h2 className="text-4xl font-bold mb-6 text-gray-700 flex items-center">
              <MessageCircle className="w-8 h-8 mr-3" />
              Contact
            </h2>
            <motion.div
              className="bg-sorbet-purple/60 p-8 rounded-lg border border-gray-700 backdrop-blur-sm text-xl"
              whileHover={{ scale: 1.02 }}
            >
              <p className="mb-6 text-gray-700">
                Interessado em embarcar nesta jornada de conhecimento? Entre em
                contato para mais informações ou para agendar uma aula
                experimental. Juntos, desbravaremos os caminhos da língua
                inglesa.
              </p>
              <div className="flex items-center">
                <h1 className="font-bold">
                  Whatsapp: (34) 99125-3527 ; Email: teacher.juassad@gmail.com ;
                  Instagram: @lerning.withju
                </h1>
              </div>
            </motion.div>
          </motion.section>
        </motion.main>

        <motion.footer
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-sorbet-purple p-6 text-center mt-12border-t border-gray-700"
        >
          <p className="text-gray-700">&copy; 2023 Teacher Ju.</p>
        </motion.footer>
      </div>
    </div>
  );
}
