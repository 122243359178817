import { Star } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

const Testimonials = () => {
  const containerRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  const starVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: (i: any) => ({
      scale: 1,
      opacity: 1,
      transition: {
        delay: i * 0.3,
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    }),
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(entry.target); // Stop observing once it's in view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div ref={containerRef}>
      <h2 className="text-4xl font-bold mb-6 text-gray-800 flex items-center">
        <Star className="w-8 h-8 mr-3" />
        Depoimentos
      </h2>
      <motion.div
        className="bg-sorbet-purple/60 p-8 rounded-lg border border-gray-700 backdrop-blur-sm"
        whileHover={{ scale: 1.02 }}
      >
        <div className="flex items-center mb-4">
          {[...Array(5)].map((_, i) => (
            <motion.div
              key={"star-" + i}
              custom={i}
              variants={starVariants}
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
            >
              <Star className="w-6 h-6 text-beige-300 mr-1" />
            </motion.div>
          ))}
        </div>
        <p className="italic text-gray-700 mb-4">
          "I have been taking English classes with Julia since January, and from
          the beginning until now, her classes have been both creative and fun.
          She is very dedicated, and I especially enjoy her thematic classes,
          such as those about the Olympics and current festive events. What I
          really appreciate is how she encourages us to speak without feeling
          embarrassed, as she makes the conversation feel very natural and
          always supports our progress. "
        </p>
        <p className="font-bold text-gray-800">- Kimberly Fernandes</p>
      </motion.div>
    </div>
  );
};

export default Testimonials;
