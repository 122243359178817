import { Star, Book, Calendar, BookCheck, BookOpen } from "lucide-react";
import { motion } from "framer-motion";

const Acesso = () => {
  return (
    <div>
      <h2 className="text-4xl font-bold mb-6 text-gray-800 flex items-center">
        <Star className="w-8 h-8 mr-3" />O que você tem Acesso
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {[
          {
            icon: Book,
            title: "Materiais interativos",
            description: (
              <p className="text-xl">
                São disponibilizados em uma pasta do Drive criada unicamente
                para você. Isso inclui materiais de vocabulário, jogos,
                apresentações de slides, etc.
              </p>
            ),
          },
          {
            icon: BookOpen,
            title: "Apostila",
            description: (
              <p className="text-xl">
                A apostila será apresentada durante a aula. Ela é uma apostila
                que engloba áudios, textos, exercício de gramática e até de
                vocabulários.
              </p>
            ),
          },
          {
            icon: BookCheck,
            title: "Provas Escritas",
            description: (
              <p className="text-xl">
                Ao final de cada 6 unidades fazemos uma prova escrita, para
                testar seus conhecimentos e identificar suas dificuldades. Não
                vale nota, é para avaliarmos seu desenvolvimento!
              </p>
            ),
          },
          {
            icon: Calendar,
            title: "Planner de escrita",
            description: (
              <p className="text-xl">
                Ao fecharmos nossas aulas, você terá acesso a um planner de
                escrita mensal, com temas para que você treine sua escrita ao
                longo de 12 meses. É para uso pessoal.
              </p>
            ),
          },
        ].map((service, index) => (
          <motion.div
            key={index}
            className="bg-sorbet-purple/60 p-8 rounded-lg border border-gray-700 backdrop-blur-sm"
            whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
          >
            <div className="flex items-center mb-4">
              <div>
                <service.icon className="w-10 h-10 mr-4 text-beige-300" />
              </div>
              <h3 className="text-2xl font-bold text-gray-800">
                {service.title}
              </h3>
            </div>
            {service.description}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Acesso;
